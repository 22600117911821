<script setup lang="ts">
import { withDefaults, ref, computed, onBeforeMount, provide, onMounted } from 'vue'
import { VIEW_SETTINGS, DEFAULT_VIEW } from '@/static-data/settings'
import { isDesktop } from '@/tools/isDesctop'
import { useSetApiKey } from '@/hooks/api-key'
import { useCurrentCity } from '@/hooks/current-city'
import { useSettingsStore } from '@/state/settings'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/state/user'
import { useUnitsStore } from '@/state/units'
import { useServicesStore } from '@/state/services'
import type { Service } from '@/static-data/service'

import ProgressStep from '@/components/progress-step.vue'
import SelectMasterCategory from '@/components/select-master-category.vue'
import UnitsMap from '@/components/units-map.vue'
import ServicesList from '@/components/services-list.vue'
import SelectedUnit from '@/components/selected-unit.vue'
import SelectedServices from '@/components/selected-services.vue'
import DatePicker from '@/components/date-picker.vue'
import SelectTime from '@/components/select-time.vue'
import SelectMaster from '@/components/select-master.vue'
import ToCreateBookingBtn from '@/components/to-create-booking-btn.vue'
import OrderSum from '@/components/order-sum.vue'
import authUserCreateBooking from '@/components/auth-user-create-booking.vue'
import ToggleMapOrList from '@/components/toggle-map-or-list.vue'
import UnitList from '@/components/unit-list.vue'
import ToSecondStep from '@/components/to-second-step.vue'
import CancelBooking from '@/components/cancel-booking.vue'
import AddReview from '@/components/add-review.vue'
import FourHands from '@/components/four-hands.vue'
import Divider from '@/components/divider.vue'
import { useEmployerCategoryGroup } from '@/state/employer-category-group'
import {useWindowsStore} from "@/state/windows";

const COMPONENTS_LIST = {
  'select-master-category': SelectMasterCategory,
  'units-map': UnitsMap,
  'services-list': ServicesList,
  'selected-unit': SelectedUnit,
  'selected-services': SelectedServices,
  'date-picker': DatePicker,
  'select-time': SelectTime,
  'select-master': SelectMaster,
  'to-create-booking-btn': ToCreateBookingBtn,
  'order-sum': OrderSum,
  'auth-user-create-booking': authUserCreateBooking,
  'toggle-map-or-list': ToggleMapOrList,
  'unit-list': UnitList,
  'to-second-step': ToSecondStep,
  'cancel-booking': CancelBooking,
  'add-review': AddReview,
  'four-hands': FourHands,
  'divider': Divider
}
const userStore = useUserStore()
const windowsStore = useWindowsStore()

const props = withDefaults(defineProps<{
  cityId: string | number
  selectedUnitId: string
  apiKey: string
  events: Record<any, any>
  referenceId?: string
  viewTheme: string
  viewSettings: string
  customViewSettings?: string
  canChangeUrl?: boolean
  selectedServicesIds?: Service['id'][],
  masterCategory?: number[],
  fourHands?: boolean,
  currency?: string
  calltouch_session_id?: string | number
}>(), {
  events: () => ({}),
  viewSettings: DEFAULT_VIEW,
  cityId: 1
})

provide('global-events', props.events)
const { toggleEmployerCategoryGroup, resetValues } = useEmployerCategoryGroup()

const servicesStore = useServicesStore()
const unitStore = useUnitsStore()
const setApiKey = useSetApiKey()
const router = useRouter()
let localRoute = 0
const isDesktopLocalVar = ref(isDesktop())
const WINDOW_TYPE = computed(() => isDesktopLocalVar.value ? 'desktop' : 'mobile')

let GLOBAL_WIDGET_SETTINGS = VIEW_SETTINGS[props.viewSettings]

if (props.customViewSettings) {
  GLOBAL_WIDGET_SETTINGS = props.customViewSettings
}


const currentStepIndex = ref(0)
const currentStep = computed(() => GLOBAL_WIDGET_SETTINGS.steps[currentStepIndex.value])
const settingsStore = useSettingsStore()
const dataLoaded = ref(false)

const columnCount = computed(() => Object.keys(currentStep.value[WINDOW_TYPE.value]).length)
const containerMaxWidth = computed(() => GLOBAL_WIDGET_SETTINGS.containerMaxWidth[WINDOW_TYPE.value])

setApiKey.setApiKey(props.apiKey)

provide('change-step', (index) => {
  currentStepIndex.value = index
})

provide('next-step', () => {
  ++currentStepIndex.value

  if (props.canChangeUrl) {
    window.location.hash = currentStepIndex.value
    localRoute = currentStepIndex.value
  }
})

addEventListener('hashchange', (event) => {
  if (!window.location.hash && Number(localRoute) === 1) { //means return to first route
    localRoute = 0
    currentStepIndex.value = 0
    return
  }

  if (window.location.hash) {
    const newHash = Number(window.location.hash.split('#')[1])

    if (newHash === 0 || newHash === 1 || newHash === 2) {
      localRoute = newHash
      currentStepIndex.value = newHash
    }
  }
})

const changeStepFromProgressBar = (newStepIndex) => {
  if (newStepIndex < currentStepIndex.value) {
    currentStepIndex.value = newStepIndex
  }
}

onBeforeMount(async () => {
  if (props.selectedServicesIds) servicesStore.setSelectedServiceIdsFromProps(props.selectedServicesIds)
  if (props.selectedUnitId) unitStore.setSelectedUnitId(props.selectedUnitId)
  if (props.fourHands) windowsStore.setParallel(props.fourHands)
  if (props.referenceId) userStore.setUser({ referenceId: props.referenceId })
  if (props.currency) settingsStore.currency.setCurrency(props.currency)
  if (props.calltouch_session_id) settingsStore.calltouchSessionId.set(props.calltouch_session_id)

  settingsStore.setSettings(VIEW_SETTINGS[props.viewSettings])
  await useCurrentCity(props.cityId)
  dataLoaded.value = true

  window.addEventListener('resize', () => {
    isDesktopLocalVar.value = isDesktop()
  })

  if (props.masterCategory) {
    resetValues()
    props.masterCategory.forEach((item) => {
      toggleEmployerCategoryGroup(item)
    })
  }
})
</script>

<template>
  <div id="container">
    <div
      class="pl-relative pl-mx-auto"
      :style="{
      maxWidth: containerMaxWidth
    }"
    >
      <progress-step
        :current-step-index="currentStepIndex"
        :steps="GLOBAL_WIDGET_SETTINGS.steps"
        @change-step="changeStepFromProgressBar"
      />

      <div
        v-if="dataLoaded"
        class="pl-grid pl-gap-4 pl-grid-cols-1 xl:pl-grid-cols-3 pl-min-h-[500px]"
      >
        <div
          v-for="(value, key) in currentStep[WINDOW_TYPE]"
          :key="key"
          :class="value.columnClasses"
        >
          <component
            v-for="component in value.components"
            :key="component"
            :is="COMPONENTS_LIST[component]"
            :class="value.componentsClasses[component]"
            :settings="value.componentsSetting[component]"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
